<template>
    <transition name="fade" mode="out-in">
        <div
            v-if="show"
            :class="[
                'flex items-center py-4 px-8 rounded-md shadow-lg bg-white relative',
                type === 'success' ? 'border-l-4 border-sg-green-100' : 'border-l-4 border-sg-red-100',
            ]">
            <div v-if="icon" class="flex-shrink-0">
                <component :is="icon" class="size-6" />
            </div>
            <div class="ml-3">
                <p class="text-sg-dark-grape-100 text-j18-regular">{{ message }}</p>
            </div>
            <button class="absolute top-4 right-5" @click="closeToast">
                <IconClose class="size-4" />
            </button>
        </div>
    </transition>
</template>

<script setup lang="ts">
import {type Component, onMounted, ref} from 'vue';
import {IconClose} from '@/assets/icons/index';
import {FEEDBACK_TYPE, TOAST_DURATION} from '@/utils/feedback.ts';

type SGToastNotificationProps = {
    message: string;
    type: FEEDBACK_TYPE.SUCCESS | FEEDBACK_TYPE.ERROR;
    autoCloseOff?: boolean;
    icon?: Component;
};

const props = withDefaults(defineProps<SGToastNotificationProps>(), {
    message: '',
    type: FEEDBACK_TYPE.SUCCESS,
    autoCloseOff: false,
    icon: () => null,
});

const show = ref(true);

const closeToast = () => {
    show.value = false;
};

onMounted(() => {
    if (props.autoCloseOff) {
        return;
    }
    setTimeout(() => {
        closeToast();
    }, TOAST_DURATION);
});
</script>
